import { getFormattedDate } from '@/utilities/org'
import i18n from '@/plugins/i18n'

const headers = (): Record<string, any>[] => [
  { text: i18n.t('common.name'), value: 'filename', width: '25%' },
  { text: i18n.t('common.notes'), value: 'notes', width: '40%' },
  {
    renderFunction: (v: Record<string, any>) => v?.description || '-',
    text: i18n.t('common.type'),
    value: 'type',
  },
  {
    renderFunction: (v: string) => getFormattedDate(v),
    text: i18n.t('common.created'),
    value: 'createdDateUtc',
  },
  {
    renderFunction: (v: string) => getFormattedDate(v),
    text: i18n.t('common.expires'),
    value: 'expiryDate',
  },
]

export default headers
