
import api from '@/utilities/api'
import { title } from '@/utilities/filters'
import { isFuture, isRequired } from '@/utilities/validations'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class UploadDocumentModal extends Vue {
  // props // ***
  @Prop({ required: true, type: Array })
  documentTypes!: Record<string, any>[]
  @Prop({ type: Boolean })
  value!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  isOnBoardingSupplier!: boolean

  // data // ***
  date: Record<string, any> | null = null
  file: Record<string, any> | null = null
  notes = ''
  selectedType: Record<string, any> | null = null
  expiryDateRequiredMessage = ''

  // computed // ***
  get accepts() {
    if (this.isOnBoardingSupplier) return '.pdf,.jpg,.png,.jpeg'

    return '*/*'
  }
  get allowedDates() {
    return (s: string): boolean => isFuture(s) === true
  }
  get fileErrorMessages(): string | undefined {
    const { validationErrors: ve } = this
    if (!ve?.length) return undefined
    else return ve.join(' | ')
  }
  get expiryDateRequired(): boolean {
    return !!this.selectedType?.requiresExpiryDate
  }
  get loading(): boolean {
    const { url } = this
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get payload(): Record<string, any> {
    const { date, file, notes } = this
    return {
      expiryDate: date?.toISO(),
      documentType: this.selectedType?.id,
      file,
      notes,
      isOnBoardingSupplier: this.isOnBoardingSupplier,
      organisationId: this.supplier?.id,
    }
  }
  get rules(): Record<string, any> {
    return {
      fileInput: [isRequired],
    }
  }
  get supplier(): Record<string, any> {
    return this.isOnBoardingSupplier
      ? this.$auth.authApiUser.organisation
      : this.$store.getters['suppliers/selectedSupplier']
  }
  get title() {
    return this.selectedType
      ? this.$t('ui.uploadX', { x: this.selectedType?.description })
      : title(String(this.$t('cmac.selectDocumentType')))
  }
  get url(): string {
    if (this.isOnBoardingSupplier) return 'supplieronboarding/Upload'
    return 'OrganisationDocument/Upload'
  }
  get validationErrors(): Record<string, any> {
    return this.$store.getters['core/validationErrors']
  }

  // watch // ***
  @Watch('documentTypes')
  onDocumentTypesChanged() {
    if (this.documentTypes.length === 1 && !this.isOnBoardingSupplier)
      this.selectedType = this.documentTypes[0]
  }
  @Watch('value')
  onValueChanged(n: boolean) {
    if (!n) this.reset()
  }

  // methods // ***
  goBack() {
    this.reset()
  }
  onClose() {
    this.reset()
    this.$emit('input', false)
  }
  async onSubmit() {
    this.resetValidation()
    const valid = await this.validateForm()
    console.log('onSubmit', this.expiryDateRequired)
    if (this.expiryDateRequired && !this.date) {
      this.expiryDateRequiredMessage = String(this.$t('cmac.expiryDateRequired'))
      return
    }
    if (!valid) return
    let formData = new FormData()
    if (this.expiryDateRequired) {
      const expiryDateOnly = this.date?.toISODate()
      formData.append('expiryDate', expiryDateOnly)
    }

    formData.append('notes', this.payload.notes)
    formData.append('documentTypeId', this.payload.documentType)
    formData.append('organisationId', this.payload.organisationId)
    formData.append('isOnBoardingSupplier', this.payload.isOnBoardingSupplier)
    formData.append('files[0]', this.payload.file)
    const url = this.url
    const options = { headers: { 'Content-Type': 'multipart/form-data' }, method: 'POST' }
    const axiosOptions = { data: formData }
    api(url, options, axiosOptions)
      .then(() => this.onUploadSuccess())
      .catch((e: Record<string, any>) => console.error(e))
  }
  onTypeSelected(e: Record<string, any>) {
    this.selectedType = e || null
  }
  onUploadSuccess() {
    this.$emit('uploaded')
    this.onClose()
  }
  onDatePickerInput() {
    this.resetValidation()
  }
  reset() {
    this.setInitialValues()
    this.resetValidation()
  }
  resetValidation() {
    this.$store.commit('core/resetErrors')
    this.expiryDateRequiredMessage = ''
  }
  setInitialValues() {
    this.file = null
    this.date = null
    this.notes = ''
    if (this.documentTypes.length > 1) this.selectedType = null
  }
  async validateForm(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    return await refs.form.validate()
  }
}
