
import { Component, Prop, Vue } from 'vue-property-decorator'
import { identifier } from '@/pages/documents/helpers/index'

@Component
export default class RequestDeletionDialog extends Vue {
  // props // ***
  @Prop({ type: Object })
  item!: Record<string, any>
  @Prop({ type: Boolean })
  value!: boolean

  // computed // ***
  get filename(): string {
    return this?.item?.filename
  }
  get reference(): string {
    return this?.item?.reference
  }
  get identifier(): string {
    return identifier(this.item)
  }
  get message(): string {
    return `A request to delete the document with ${this.identifier} will be sent to CMAC.`
  }

  // methods // ***
  onCancel() {
    this.$emit('cancel')
  }
  onConfirm() {
    this.$emit('confirm', this.item)
  }
}
