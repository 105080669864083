import { DOCUMENT_TYPES } from '@/utilities/constants'

const { INVOICE, UNKNOWN } = DOCUMENT_TYPES

const items = (context: Record<string, any>): Record<string, any>[] =>
  context.documents
    .filter(
      (v: Record<string, any>) =>
        !!v?.documentType && ![INVOICE, UNKNOWN].includes(v.documentType.id)
    )
    .map((v: Record<string, any>): Record<string, any> => ({ ...v, id: v.organisationDocumentId }))
    .map(
      (v: Record<string, any>): Record<string, any> => ({
        ...v,
        itemSpecificConfig: {
          class: context.deletionRequested(v) ? 'deletionRequested' : '',
          actions: {
            vueComponent: {
              props: {
                actions:
                  context.actions?.map((a: Record<string, any>) => ({
                    ...a,
                    disabled: context.actionIsDisabled(v, a),
                    loading: context.actionIsLoading(v, a),
                    title: context.getActionTitle(v, a),
                  })) || [],
              },
            },
          },
        },
        type: context.getDocumentTypeById(v.documentType.id),
      })
    )

export default items
