
import { Component, Vue, Watch } from 'vue-property-decorator'
import RequestDeletionDialog from './RequestDeletionDialog'
import UploadDocumentModal from './UploadDocumentModal'
import { headers, items } from '@/pages/documents/helpers/documents/index'
import { DOCUMENT_TYPES } from '@/utilities/constants'
import api from '@/utilities/api'
import { downloadAjax, sortAlphabeticallyByKey } from '@/utilities/functions'

const { INVOICE, UNKNOWN } = DOCUMENT_TYPES

interface RequestDeletionDialogInterface {
  item: Record<string, any> | null
  value: boolean
}

@Component({
  components: {
    'c-upload-document-modal': UploadDocumentModal,
    'c-request-deletion-dialog': RequestDeletionDialog,
  },
})
export default class Documents extends Vue {
  // data // ***
  uploadModalOpen = false
  requestDeletionDialog: RequestDeletionDialogInterface = { item: null, value: false }
  values: Record<string, any> = { documentTypes: [] }

  // computed // ***
  get actionHeader() {
    const { actions } = this
    return { vueComponent: { name: 'c-table-actions', props: { actions } } }
  }
  get actions() {
    return [
      {
        callback: (item: Record<string, any>) => this.onDownload(item),
        color: 'accent',
        icon: 'file_download',
        value: 'download',
      },
      {
        callback: (item: Record<string, any>) => this.onDelete(item),
        color: 'secondary',
        icon: 'delete',
        value: 'delete',
      },
    ]
  }
  get deleting(): boolean {
    const { delete: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get documents() {
    return this.$store.getters['suppliers/documents']
  }
  get documentTypes() {
    const types = this.$store.getters['suppliers/documentTypes'].filter(
      (t: Record<string, any>) => ![INVOICE, UNKNOWN].includes(t?.id)
    )
    return sortAlphabeticallyByKey(types, 'description')
  }
  get features(): Record<string, any> {
    return this.$auth.features
  }
  get fetchingDocuments(): boolean {
    const { fetch: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get fetchingDocumentTypes(): boolean {
    const { types: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get headers() {
    return headers()
  }
  get items() {
    return items(this)
  }
  get loading(): boolean {
    const { fetchingDocuments, fetchingDocumentTypes } = this
    return fetchingDocuments || fetchingDocumentTypes
  }
  get organisationId(): string {
    return String(this.supplier?.id)
  }
  get params() {
    const { organisationId, values } = this
    const { documentTypes: documentTypeIds } = values
    return { documentTypeIds, organisationId, ReturnAllResults: true }
  }
  get searchFields(): Record<string, any> {
    const { documentTypes: dt } = this
    const dtItems = dt.map((i: Record<string, any>) => ({ text: i.description, value: i.id }))
    // todo: add function to enforce unique filename after the 422 error fixed
    const filenameUnique = () => true
    return { documentType: { items: dtItems, rules: [filenameUnique] } }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get uploading(): boolean {
    const { upload: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get urls(): Record<string, string> {
    return {
      delete: 'OrganisationDocument/RequestDelete',
      fetch: 'OrganisationDocument/SearchDocumentsForSupplier',
      types: 'OrganisationDocument/DocumentTypesForSupplier',
      upload: 'OrganisationDocument/Upload',
    }
  }

  // created // ***
  created() {
    this.redirectIfDisabled()
    this.onCreated()
  }
  async onCreated() {
    await this.fetchDocumentTypes()
    this.fetch()
  }

  // watch // ***
  @Watch('features')
  onFeaturesChanged(): void {
    this.redirectIfDisabled()
  }
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch()
  }

  // methods // ***
  actionIsDisabled(item: Record<string, any>, action: Record<string, any>): boolean {
    if (action.value === 'delete') return this.deleteDisabledForItem(item)
    else if (action.value === 'download') return this.downloadDisabledForItem(item)
    else return false
  }
  actionIsLoading(item: Record<string, any>, action: Record<string, any>): boolean {
    return !(item && action) // TODO: set this up once the download/delete endpoints are in
  }
  deleteDisabledForItem(item: Record<string, any>): boolean {
    return this.deletionRequested(item)
  }
  deletionRequested(item: Record<string, any>): boolean {
    const { attributes: a } = item
    if (!a || !a.length) return false
    else return a?.indexOf('DeletionRequested') !== -1
  }
  downloadDisabledForItem(item: Record<string, any>): boolean {
    return this.deletionRequested(item)
  }
  openRequestDeletionDialog(i: Record<string, any>) {
    this.requestDeletionDialog.item = i
    this.requestDeletionDialog.value = true
  }
  async fetch() {
    const { params: data, urls } = this
    const { fetch: url } = urls
    const options = { data, json: true, method: 'POST', url }
    await this.$store.dispatch('suppliers/fetchDocuments', options)
  }
  async fetchDocumentTypes() {
    const { types: url } = this.urls
    await this.$store.dispatch('suppliers/fetchDocumentTypes', { url })
  }
  getActionTitle(item: Record<string, any>, action: Record<string, any>): string {
    const delDisabledMessage = 'Document deletion has been requested.'
    if (action.value === 'delete' && this.actionIsDisabled(item, action)) return delDisabledMessage
    else if (action.value === 'delete') return 'Delete'
    else if (action.value === 'download') return 'Download'
    else return ''
  }
  getDocumentTypeById(id: string): Record<string, any> | undefined {
    return this.documentTypes.find((v: Record<string, any>) => v.id === id)
  }
  getDownloadUrl(i: Record<string, any>): string {
    return `OrganisationDocument/open/${i?.id}`
  }
  async onDownload(i: Record<string, any>) {
    await downloadAjax(this.getDownloadUrl(i), i?.filename)
  }
  onDelete(i: Record<string, any>) {
    this.openRequestDeletionDialog(i)
  }
  onRequestDeletionDialogCancel(): void {
    this.resetRequestDeletionDialog()
  }
  onRequestDeletionDialogConfirm(i: Record<string, any>): void {
    this.requestDeletion(i)
  }
  onRequestDeletionDialogInput(e: Record<string, any>): void {
    if (!e) this.resetRequestDeletionDialog()
  }
  onSearch(): void {
    this.fetch()
  }
  onUpload(): void {
    this.fetch()
  }
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('documentManagement'))
      this.$router.push('/errors/404')
  }
  requestDeletion(item: Record<string, any>) {
    const { delete: url } = this.urls
    const filename = this.requestDeletionDialog?.item?.filename || '?'
    const message = `A request to delete the document with filename "${filename}" has been sent.`
    const data = { DocumentId: item?.id, OrganisationId: this.supplier?.id }
    api(url, { data, json: true, method: 'POST' }, undefined).then(() => {
      this.$store.dispatch('core/launchSnackbar', { color: 'success', message })
      this.resetRequestDeletionDialog()
      this.fetch()
    })
  }
  resetRequestDeletionDialog() {
    this.requestDeletionDialog.value = false
    this.requestDeletionDialog.item = null
  }
}
